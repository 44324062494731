import { Injectable } from '@angular/core';
import { MfAuthenticationService } from '@app/shared/authentication/authentication.service';
import { isMfUserRole, MfUserRolesEnum } from '@app/shared/authentication/types/user_roles.enum';
import { MfUserStorage } from '@app/shared/authentication/user/user-storage.service';
import { MfBankCustomizationService } from '@app/shared/bank-customization/bank-customization.service';
import { MfDataBankInterface, MfDataUserInterface } from '@app/shared/data/data.interface';
import { MfNavigationService } from '@app/shared/navigation/navigation.service';
import { ADMIN_USER_ROLES } from '@app/shared/upsert-user/upsert-user.const';
import { MfUserCustomizationService } from '@app/shared/user-customization/user-customization.service';
import { MfUserDataService } from '@app/user/services/data/data.service';
import { jwtDecode } from 'jwt-decode';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfUserService {
  constructor(
    private userStorage: MfUserStorage,
    private dataService: MfUserDataService,
    private userCustomizationService: MfUserCustomizationService,
    private bankCustomizationService: MfBankCustomizationService,
    private navigationService: MfNavigationService
  ) {
    if (!this.isLoggedIn()) {
      this.clear();
    }
  }

  public getUser(): MfDataUserInterface | undefined {
    return this.userStorage.getUser();
  }

  public getBank(): MfDataBankInterface | undefined {
    const user = this.getUser();

    if (!user?._embedded?.bank) {
      return undefined;
    }

    return user._embedded.bank;
  }

  public updateBank(bank: MfDataBankInterface): void {
    const user = this.getUser();

    if (!user) {
      return;
    }

    if (!user._embedded) {
      user._embedded = {};
    }

    user._embedded.bank = bank;
    this.userStorage.setUser(user);
  }

  public getRoles(): MfUserRolesEnum[] {
    const user = this.getUser();

    if (user && Array.isArray(user.roles)) {
      return user.roles;
    }

    return [];
  }

  public isBankUser(): boolean {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    return !!user.isBankUser;
  }

  public isPartnerUser(): boolean {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    return !!user.isPartnerUser;
  }

  public isAdminUser(): boolean {
    return (
      !this.getBank() && this.hasOneOfRoles(ADMIN_USER_ROLES.map((role) => String(role.value)))
    );
  }

  public hasValidToken(token: string): boolean {
    try {
      const payload = jwtDecode(token);

      return !!payload?.exp && payload?.exp > Date.now() / 1000 + 60 * 30;
    } catch (e) {
      return false;
    }
  }

  public isLoggedIn(): boolean {
    const token = MfAuthenticationService.getAccessToken();

    return !!token && this.hasValidToken(token);
  }

  /** @deprecated Use isLoggedIn instead */
  public isLoggedInWithRoleCheck(): boolean {
    return this.isLoggedIn();
  }

  public hasRole(role: MfUserRolesEnum | string): boolean {
    const userRoles = this.getRoles();

    return isMfUserRole(role) && userRoles.includes(role);
  }

  public hasOneOfRoles(roles: (MfUserRolesEnum | string)[]): boolean {
    const userRoles = this.getRoles();

    return userRoles.some((userRole) => roles.includes(userRole));
  }

  public getUserDefaultRoute(): string {
    const roles: string[] = this.getRoles();

    if (roles.includes(MfUserRolesEnum.CAMPAIGN_MANAGER)) {
      return 'finanzhaus';
    } else if (roles.includes(MfUserRolesEnum.PARTNER_MARKETING_MANAGER)) {
      return 'kampagnen';
    } else if (
      roles.includes(MfUserRolesEnum.DATA_MANAGER) ||
      roles.includes(MfUserRolesEnum.PARTNER_DATA_MANAGER)
    ) {
      return 'datentransfer';
    } else if (roles.includes(MfUserRolesEnum.BANK_ADMIN)) {
      return 'bank-administration';
    } else if (roles.includes(MfUserRolesEnum.REVISION)) {
      return 'revision';
    } else if (roles.includes(MfUserRolesEnum.PARTNER_ADMIN)) {
      return 'partner/administration';
    } else if (roles.includes(MfUserRolesEnum.PARTNER_BANK_EMPLOYEE)) {
      return 'partner/adressdaten/freigeben';
    } else if (roles.includes(MfUserRolesEnum.BACK_OFFICE)) {
      return 'marktfolge';
    } else {
      return this.navigationService.suggestDefaultRoute();
    }
  }

  public isAllowedToAccessRoute(url?: string): boolean {
    if (url) {
      return this.navigationService.userCanAccessUrl(url);
    }

    return false;
  }

  public load(): Observable<MfDataUserInterface> {
    const subject: Subject<MfDataUserInterface> = new Subject();

    this.dataService
      .getMe()
      .pipe(
        map((user: MfDataUserInterface) => {
          this.userCustomizationService.setCompleteDataFromString(user.configuration || '');
          this.bankCustomizationService.setCompleteDataFromString(
            user?._embedded?.bank?.configuration || '{}'
          );

          return user;
        })
      )
      .subscribe({
        next: (user) => {
          this.setUser(user);
          subject.next(user);
          subject.complete();
        },
        error: (error: any) => {
          this.clear();
          subject.error(error);
          subject.complete();
        },
      });

    return subject.asObservable();
  }

  setUser(user: MfDataUserInterface): void {
    if (!this.isLoggedIn()) {
      this.clear();

      return;
    }

    this.userStorage.setUser(user);
  }

  clear(): void {
    this.userStorage.clearUser();
  }
}
