export enum MfMime {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  WEBP = 'image/webp',
  GIF = 'image/gif',
  ZIP = 'application/zip',
  TEXT = 'text/plain',
  MP4 = 'video/mp4',
  M4V = 'video/x-m4v',
  QUICKTIME = 'video/quicktime',
  PDF = 'application/pdf',
  PFX = 'application/x-pkcs12',
  CSV = 'text/csv',
  CSV_ALTERNATIVE = 'text/comma-separated-values',
  CSV_EXCEL = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  MS_WORD_DOC = 'application/msword', // .doc .dot
  MS_WORD_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  MS_EXCEL_MAKRO = 'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
  MS_ACCESS = 'application/msaccess', // .accdb
}
